/** 页面顶部公告组件 */

import { helpers } from '@common'
import styles from './style.m.scss'

const className = helpers.classNames.react(styles)

export default ({ msg = '' }) => {
  if (!msg) return null
  return (
    <div {...className(styles.notice)}>
      <div {...className(styles.icon)}></div>
      <p {...className(styles.desc)}>{msg}</p>
    </div>
  )
}
