import React, { Fragment, PureComponent } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Modal } from 'antd-mobile'
import _ from 'lodash'
import { globalActionCreator } from '@pc/common/globalActions'
import {
  limitCapacityMap,
  PHONE_COUNTRY_CODE_MAP,
  SECURITY_VERIFICATION_BIZ_TYPE,
  SCENARIO_NUMBER_MAP,
  LOGIN_METHOD,
  SLIDER_CONFIG,
  FACE_CONFIG,
} from '@pc/common/macro'
import { actionCreator } from './store'
import { helpers, withBasic } from '@common'
import { ScrollView } from '@Arui'
import { store } from '../../store'
import Logger, * as elementId from '../../config/logger'
import { Translate } from '../../config/translate'
import {
  getCommonParams,
  getSecurityCacheData,
} from '@/components/Security/utils'
import styles from './style.m.scss'

import Icon from '@pc/components/Icon'
import { Button } from '@pc/components/Button'
import Notice from '@pc/components/Notice'
import LoginPasswordForm from './components/LoginPasswordForm'
import PromotionCodeForm from './components/PromotionCodeForm'

import CouponBar from './components/CouponBar'

import icon_creditAvailable from '../../assets/img/creditAvailable_new.png'
import icon_discount from '../../assets/img/icon_discount.png'
import PasswordAndCodeForm from './components/PasswordAndCodeForm'
import loggerConfig from './logger.config'
import QuickPaymentInstruction from './components/QuickPaymentInstruction'
import QuickPaymentReminder from './components/QuickPaymentReminder'

import { actionCreator as loginActionCreator } from '../login/store'
import { push } from 'connected-react-router'
import BizTracker from '@pc/common/tracker/bizTracker'
import { getApiBaseUrl } from '@pc/config/utils'
import { getSmDeviceData, ALDeviceSDKInstance } from '@/components/device-sdk'
import withPerfCollect from '@/components/withPerfCollect'

import { dynamic } from 'umi'
import { SecurityVerificationMobile as SecurityVerification } from '@/components/SecurityVerification'
import { PayVerification } from '@pc/components/PayVerification'

const PaymentDetails = dynamic(() => import('./components/PaymentDetails'))
const RepaymentDateList = dynamic(() =>
  import('./components/RepaymentDateList').then((res) => res.RepaymentDateList),
)
const PaymentProcess = dynamic(() =>
  import('../login/components/PaymentProcess'),
)
const PeriodList = dynamic(() =>
  import('./components/PeriodList').then((res) => res.PeriodList),
)
const CouponListContainer = dynamic(() =>
  import('./components/CouponListContainer'),
)
const DownpaymentRateListContainer = dynamic(() =>
  import('./components/DonwpaymentRateContainer'),
)
const ServiceAgreementIframe = dynamic(() =>
  import('./components/ServiceAgreementIframe').then(
    (res) => res.ServiceAgreementIframe,
  ),
)

const { storage, URL, getBizEnv } = helpers
const className = helpers.classNames.react(styles)
@withBasic
class OrderDetails extends PureComponent {
  constructor() {
    super()
    this.saLogger = Logger.getInstance()
  }

  // 获取使用优惠券之后的优惠金额
  getDiscountPrice = (coupons) => {
    if (coupons) {
      const discountAmount = coupons.get('discountAmount')
      const discountOfTotalPrice = coupons.get('discountOfTotalPrice')
      return { discountOfTotalPrice, discountAmount }
    }
    return {}
  }

  handleConfirmPay = () => {
    const {
      curPeriod,
      isSmallPay,
      firstNonPasswordPay,
      toggleQuickPaymentReminderVisible,
      opreateBeforePlaceOrder,
      quickPaymentReminderHasOpen,
    } = this.props
    BizTracker.click({ cn: 6 })
    if (isSmallPay && firstNonPasswordPay && !quickPaymentReminderHasOpen) {
      // 只有第一次使用小额免密的时候才需要弹小额免密的提示框
      toggleQuickPaymentReminderVisible(true)
    } else {
      opreateBeforePlaceOrder(curPeriod)
    }
  }

  render() {
    const {
      translate,
      formatPrice,
      choosePeriod,
      showPlanDetails,
      closePopup,
      opreateBeforePlaceOrder,
      openAgreement,
      loginBeforePlaceOrder,
      userSignOut,
      orderButtonDisabled,
      securityVerificationOnSuccess,
      securityVerificationOnRejected,
      securityVerificationOnClose,
      coupons,
      curPeriod,
      currencyCode,
      plans,
      balance,
      curMonthlyPay,
      countryId,
      countryCode,
      languageCode,
      downPayment,
      curPayment,
      basicPopup,
      downpaymentRate,
      curPaymentStrategy,
      periodOfInterestFree,
      insufficientLimit,
      showDownPaymentRate,
      showFullpayment,
      orderDetailBanner,
      securityVerificationVisible,
      showSmallPayController,
      isSmallPay,
      goSetIsSmallPay,
      openSmallPayAgreement,
      showPromptModal,
      handelShowPromptModal,
      userSetRepaymentDate,
      paymentPasswordVerificationSuccess,
      paymentPasswordVerificationFail,
      paymentPasswordVerificationClose,
      paymentPasswordReportDeviceData,
      paymentPasswordGetSmDeviceData,
      showPayVerificationModal,
      clickPayTime,
      payPwdBusinessId,
      notice,
    } = this.props

    // 使用优惠券之后的折扣
    const { discountOfTotalPrice, discountRate } =
      this.getDiscountPrice(coupons)
    const originalPrice = plans.get('price')
    const hasDiscount =
      discountOfTotalPrice && discountOfTotalPrice !== originalPrice

    const discountDPprice = plans.getIn([
        'discountPriceOfDownPaymentRate',
        downpaymentRate,
      ]),
      discountDPrate = plans.getIn([
        'discountOfDownPaymentRate',
        downpaymentRate,
      ]),
      hasDPdiscount =
        discountDPprice && discountDPprice !== downPayment && curPeriod !== '0'

    const riskInstallmentResult = plans.get('riskInstallmentResult')

    const securityCacheParams = getSecurityCacheData()
    const loginType = URL.getParam('loginType') || securityCacheParams.loginType
    const loginTime = URL.getParam('loginTime') || securityCacheParams.loginTime
    const riskSign = URL.getParam('riskSign') || securityCacheParams.riskSign

    return [
      <ScrollView {...className('container mobile-container')} key={'body'}>
        {countryCode === 'ID' &&
        orderDetailBanner &&
        orderDetailBanner.imagePhone ? (
          <div {...className('top-banner')} onClick={this.clickBanner}>
            <img src={orderDetailBanner.imagePhone} alt="" />
          </div>
        ) : null}
        <Notice msg={notice} />
        <fieldset {...className('field-content')}>
          <section {...className('content-area peace')}>
            <h3 {...className('content-header')}>{translate('订单金额')}</h3>
            <div {...className('content-body')}>
              <div {...className('order-amount-area font-lato-reg')}>
                {!plans.size ? null : hasDiscount ? (
                  <div {...className('order-amount-discount fadeIn')}>
                    <span>{currencyCode}</span>
                    <span>{formatPrice(discountOfTotalPrice)}</span>
                    <s {...className('price')}>
                      {/* {currencyCode} */}
                      {formatPrice(originalPrice)}
                    </s>
                    {discountRate ? (
                      <i {...className('icon-discount-wrapper')}>
                        <Icon
                          {...className('icon-discount')}
                          icontype="image"
                          src={icon_discount}
                        />
                        <span>{`-${Math.round(
                          (1 - discountRate) * 100,
                        )}%`}</span>
                      </i>
                    ) : null}
                  </div>
                ) : (
                  <div {...className('order-amount fadeIn font-lato-medium')}>
                    <span>{currencyCode}</span>
                    <span>{formatPrice(originalPrice)}</span>
                  </div>
                )}
              </div>
              <div {...className('credit-bar')}>
                <Icon
                  {...className('money-pack')}
                  icontype="image"
                  src={icon_creditAvailable}
                />
                <span {...className('credit-bar-middle')}>
                  {translate('可用额度')}
                </span>
                <span>
                  {currencyCode} {formatPrice(balance)}
                </span>
              </div>
              <div {...className('limit-tips')}>
                {riskInstallmentResult &&
                riskInstallmentResult === limitCapacityMap.LIMIT_OUT_CREDIT ? (
                  <p>* {translate('额度不足，请提升额度')}</p>
                ) : null}
              </div>
              <div {...className('coupon-bar')}>
                <CouponBar />
              </div>
            </div>
          </section>
        </fieldset>

        <fieldset {...className('field-content')}>
          {insufficientLimit || !showDownPaymentRate ? null : (
            <section {...className('content-area downpayment-area')}>
              <h3
                {...className('content-header')}
                onClick={() => {
                  // #region 【ID1072354】openpay 前端优化（2）
                  // 子项 13 所有订单（包括零首付、有固定首付比例、可选首付比例）：默认首付金额，取消可选择首付比例的选项和二次选项弹窗。
                  //1.只有用户额度充足的情况下，才能选择首付比例
                  //2.部分0首付用户不允许选择首付比例，因为部分0首付用户后台只返回了0首付选项，强制0首付
                  //当然这里如果showDownPaymentRate=false的话，整个section都不会显示，为了与PC端保持统一
                  // riskInstallmentResult === limitCapacityMap.LIMIT_SUFFICIENT &&
                  //   showDownPaymentRate &&
                  //   showPyamentRateList()
                  // #endRegion
                }}
              >
                <span {...className('downpayment-icon font-lato-bol')}>
                  {curPeriod === '0'
                    ? '100%'
                    : `${parseInt(downpaymentRate * 100, 10)}%`}
                </span>
                <span>{translate('首付')}</span>
              </h3>
              <div {...className('content-body downpayment-area-body')}>
                {hasDPdiscount ? (
                  <p
                    {...className(
                      'downpayment-amount downpayment-discount-wrapper',
                    )}
                  >
                    <span>{currencyCode}</span>
                    <span {...className('fadeIn font-lato-medium')}>
                      {!plans.size ? null : formatPrice(discountDPprice)}
                    </span>
                    <s {...className('price')}>
                      {currencyCode}
                      {formatPrice(downPayment)}
                    </s>
                    {discountDPrate ? (
                      <i {...className('icon-discount-wrapper')}>
                        <Icon
                          {...className('icon-discount')}
                          icontype="image"
                          src={icon_discount}
                        />
                        <span>{`-${Math.round(
                          (1 - discountDPrate) * 100,
                        )}%`}</span>
                      </i>
                    ) : null}
                  </p>
                ) : (
                  <p {...className('downpayment-amount')}>
                    <span>{currencyCode}</span>
                    <span {...className('fadeIn font-lato-medium')}>
                      {!plans.size ? null : formatPrice(downPayment)}
                    </span>
                  </p>
                )}
              </div>
            </section>
          )}

          <section {...className('content-area period-list-area')}>
            <h3 {...className('content-header')}>
              <span>{translate('分期数（月）')}</span>
            </h3>
            <div {...className('content-body periodList-container')}>
              {!plans.size ? null : (
                <PeriodList
                  list={curPaymentStrategy}
                  curPeriod={curPeriod}
                  periodOfInterestFree={periodOfInterestFree}
                  choosePeriod={choosePeriod(this.props.orderId)}
                  showFullpayment={showFullpayment}
                />
              )}
            </div>
          </section>

          <section
            {...className('content-area payPlan-area', {
              // 用户已经选择过还款日，清掉下margin 和 border
              noBorder: userSetRepaymentDate,
            })}
          >
            <h3 {...className('content-header')}>
              <span>{translate('分期付款计划')}</span>
              {curPeriod === '0' ? null : (
                <Icon
                  icontype="svg"
                  name="remind"
                  onClick={showPlanDetails}
                  {...className('icon-reminder')}
                />
              )}
            </h3>
            <div {...className('content-body')}>
              {!plans.size ? null : (
                <p {...className('payPlan-info-text font-lato-medium')}>
                  {curPeriod === '0'
                    ? `${currencyCode}${formatPrice(curPayment)}`
                    : `${currencyCode}${formatPrice(
                        curMonthlyPay,
                      )} X ${curPeriod}${translate('月')}`}
                </p>
              )}
            </div>
          </section>
          {/* 仅对未设置还款日的用户展示，默认选中 ‘25号’ 选项； */}
          {!userSetRepaymentDate && <RepaymentDateList />}
        </fieldset>

        {insufficientLimit ? (
          <fieldset {...className('field-content')}>
            <section {...className('content-area applylimit-entry-area')}>
              <div {...className('applylimit-header')}>
                <i>
                  <Icon
                    icontype="svg"
                    name="applylimit"
                    {...className('applylimit-icon')}
                  />
                </i>

                {riskInstallmentResult !==
                limitCapacityMap.LIMIT_NO_CREDIT_NEW_USER ? (
                  <p {...className('font-lato-reg')}>
                    <span>
                      {translate(
                        '提升额度享受更多分期支付服务，最高可提升额度',
                      )}
                    </span>
                    <span {...className('font-din-bold')}>
                      {translate('2 juta')}
                    </span>
                  </p>
                ) : (
                  <p {...className('font-lato-reg')}>
                    <span>
                      {translate('申请开通Akulaku分期支付，最高可获额度')}
                    </span>
                    <span {...className('font-din-bold')}>
                      {translate('Rp 25.000.000')}
                    </span>
                  </p>
                )}
              </div>
              <div {...className('applylimit-body')}>
                <PaymentProcess
                  hasDownload={true}
                  size="small"
                  {...className('applylimit-process')}
                />
              </div>
            </section>
          </fieldset>
        ) : null}

        <footer {...className('footer-sticky')} key={'footer'}>
          <Button
            text={translate('确认支付')}
            detectIphoneX={false}
            loading={orderButtonDisabled}
            onClick={_.debounce(this.handleConfirmPay, 200)}
            {...className('confirm-pay fz-15')}
          />
          {!showSmallPayController ? null : (
            <section {...className('content-area noBorder smallpay-container')}>
              <div {...className('smallpay-controller')}>
                {isSmallPay ? (
                  <Icon
                    onClick={() => goSetIsSmallPay(false)}
                    name="registered_ico_choose_noselected3x"
                    icontype="svg"
                    {...className('smallpay-icon')}
                  />
                ) : (
                  <Icon
                    onClick={() => goSetIsSmallPay(true)}
                    name="iconNocheck"
                    icontype="svg"
                    {...className('smallpay-icon grey')}
                  />
                )}
              </div>
              <div {...className('smallpay-agreement')}>
                <span>{translate('同意')} </span>
                <a
                  onClick={() =>
                    openSmallPayAgreement(countryCode, languageCode)
                  }
                >
                  {translate('Akulaku小额分期免登录密码验证条款')}
                </a>
              </div>
            </section>
          )}
          <section {...className('content-area noBorder')}>
            <p
              {...className('service-agreement', {
                'service-container': showSmallPayController,
              })}
            >
              <span>{translate('同意')} </span>
              <a onClick={() => openAgreement(countryCode, languageCode)}>
                {translate('服务协议')}
              </a>
            </p>
          </section>
          {storage.getSession('tokenLogin') ? null : (
            <p {...className('log-out')} onClick={userSignOut}>
              {translate('登出')}
            </p>
          )}
        </footer>
      </ScrollView>,
      // <RiskCodeForm
      //   key={'codeForm'}
      //   onSuccess={captcha => postRisckCheckCaptcha(captcha, operationId)}
      // />,
      <PasswordAndCodeForm key={'passwordNcode'} />,
      <PromotionCodeForm key="promotionCode" />,
      <LoginPasswordForm
        key={'loginPwdForm'}
        onSuccess={(loginPwd) => loginBeforePlaceOrder(loginPwd, curPeriod)}
      />,
      <Modal
        popup
        visible={basicPopup.get('show')}
        onClose={closePopup}
        animationType="slide-up"
        maskClosable={false}
        key={'popup'}
      >
        {this.makePopupContent()}
      </Modal>,
      showPayVerificationModal ? (
        <PayVerification
          locale={languageCode ? languageCode.toLowerCase() : 'in'}
          env={getBizEnv()}
          apiBaseURL={getApiBaseUrl('/capi')}
          countryId={countryId}
          bizType={SECURITY_VERIFICATION_BIZ_TYPE.ADD_ORDER}
          saParams={{
            pageId: 'openpay03',
            pageName: 'openpay confirm order page',
          }}
          onSuccess={paymentPasswordVerificationSuccess}
          onClose={paymentPasswordVerificationClose}
          onRequestError={paymentPasswordVerificationFail}
          reportDeviceData={paymentPasswordReportDeviceData}
          getSmDeviceData={paymentPasswordGetSmDeviceData}
        />
      ) : null,
      securityVerificationVisible ? (
        <SecurityVerification
          visible={securityVerificationVisible}
          key={'SecurityVerification'}
          locale={languageCode ? languageCode.toLowerCase() : 'in'}
          languageCode={languageCode ? languageCode.toLowerCase() : 'in'}
          env={getBizEnv()}
          bizType={SECURITY_VERIFICATION_BIZ_TYPE.ADD_ORDER}
          deviceId={this.props.device_id}
          deviceUuid={helpers.storage.getSession('deviceData')?.uuid}
          extraParams={{
            ...getCommonParams(),
            amount: hasDiscount ? discountOfTotalPrice : originalPrice,
            isSecretPay: showSmallPayController && isSmallPay ? 0 : 1,
            orderId: this.props.orderId,
            device_id: this.props.device_id,
            order_credit: curMonthlyPay * curPeriod,
            clickPayTime,
            downPayment,
            payPwdBusinessId: payPwdBusinessId || null,
            fullProductId: helpers.storage.getSession('full_product_id'),
            loginType,
            loginTime,
            riskSign,
          }}
          userId={helpers.storage.getSession('openpay_uid')}
          appBrand="op"
          saParams={{
            pageId: 'openpay03',
            pageName: 'openpay confirm order page',
          }}
          countryCode={`+${PHONE_COUNTRY_CODE_MAP[countryId]}`}
          phoneNumber={this.phoneDesensitization()}
          onSuccess={securityVerificationOnSuccess}
          onRejected={securityVerificationOnRejected}
          onClose={securityVerificationOnClose}
          apiBaseURL={getApiBaseUrl('/capi')}
          onRequestError={this.handleSecurityVerificationRequestError}
          reportDeviceData={{
            pin: paymentPasswordReportDeviceData,
          }}
          getSmDeviceData={paymentPasswordGetSmDeviceData}
          sliderData={SLIDER_CONFIG}
          faceOptions={FACE_CONFIG}
        />
      ) : null,
      <QuickPaymentInstruction key="quickPaymentInstruction" />,
      <QuickPaymentReminder
        key="quickPaymentReminder"
        onOk={_.debounce(() => opreateBeforePlaceOrder(curPeriod), 200)}
      />,
      <Modal
        {...className('prompt_modal')}
        key={'prompt_modal'}
        transparent
        closable={true}
        maskClosable={false}
        title={translate('温馨提示')}
        visible={showPromptModal}
        onClose={() => handelShowPromptModal(false)}
        footer={[
          {
            text: translate('确认'),
            onPress: () => handelShowPromptModal(false),
          },
        ]}
      >
        <p style={{ marginTop: 12 }}>
          {translate('当前付款正在处理中，请稍后再试')}
        </p>
      </Modal>,
    ]
  }

  // 安全组件请求报错 errorInfo 有两种情况 一种是接口的response 一种是Error 实例
  handleSecurityVerificationRequestError = ({ error, type }) => {
    const { securityVerificationLogout, securityVerificationOnClose } =
      this.props
    this.saLogger.click({
      ...loggerConfig.confirm_payment,
      code: error.errCode,
      Aku_msg: error.errMsg,
    })
    if (error.errCode === '401') {
      securityVerificationOnClose()
      securityVerificationLogout()
    }
  }

  // 手机号脱敏 手机号从url获取 如果获取不到就从localStorage 拿
  phoneDesensitization = () => {
    const phone = (URL.allParam || {}).phone || storage.get('phoneNumber')
    return phone ? `${phone.slice(0, 2)} **** ${phone.slice(-4)}` : phone
  }

  makePopupContent = (discountDPprice, discountDPrate, hasDPdiscount) => {
    const { translate, basicPopup, closePopup, countryCode, languageCode } =
      this.props
    const popupName = basicPopup.get('name')

    if (popupName === 'planDetails') {
      this.saLogger.click({
        ...loggerConfig.click_repayment_plan,
        ALpayOrderID: this.props.orderId,
      })
      return (
        <Fragment>
          <PaymentDetails />
          <Button
            text={translate('我知道了')}
            sharp={true}
            onClick={closePopup}
          />
        </Fragment>
      )
    } else if (popupName === 'paymentRateList') {
      return (
        <DownpaymentRateListContainer
          discountDPprice={discountDPprice}
          discountDPrate={discountDPrate}
          hasDPdiscount={hasDPdiscount}
        />
      )
    } else if (popupName === 'couponList') {
      return <CouponListContainer />
    } else if (popupName === 'ServiceAgreementIframe') {
      return (
        <ServiceAgreementIframe
          countryCode={countryCode}
          languageCode={languageCode}
          closePopup={closePopup}
        />
      )
    }
  }

  clickBanner = () => {
    const { orderDetailBanner } = this.props
    if (orderDetailBanner && orderDetailBanner.jumpUrl) {
      window.location.href = orderDetailBanner.jumpUrl
    }
    this.saLogger.onPageClick(elementId.CLICK_BANNER_ORDER_CONFIRM)
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { basicDialog } = this.props
    const dialogType = nextProps.basicDialog.get('dialogType')
    const dialogContent = nextProps.basicDialog.get('content')
    const shouldShowDialog =
      !basicDialog.get('show') && nextProps.basicDialog.get('show')

    shouldShowDialog && this.controlGlobalDialog(dialogType, dialogContent)
  }

  controlGlobalDialog(dialogType, dialogContent) {
    const { cancelOldPayment, showFullPayDialog, translate, currencyCode } =
      this.props

    //如果弹窗类型是设置全款
    if (dialogType === 'setFullPay') {
      showFullPayDialog(dialogContent)
    } else if (dialogType === 'cancelOldPayment') {
      const formerPaymentObj = storage.get('formerPayment')

      const content = (
        <div {...className('cancel-order-tips')}>
          <div>
            <p>{translate('旧的付款将被取消：')}</p>
            <p>
              {translate('首付：')}
              {currencyCode}{' '}
              {formerPaymentObj && formerPaymentObj.formerDownPayment}
            </p>
            <p>
              {translate('分期数：${period}期', {
                period: formerPaymentObj && formerPaymentObj.formerPeriod,
              })}
            </p>
          </div>
        </div>
      )
      cancelOldPayment(content)
    }
  }

  getAndReportConfig = async () => {
    const methodToken = URL.getParam('loginMethodToken')
    const { data } = await this.props.dispatch(
      globalActionCreator.globalGetReportConfig(methodToken),
    )
    const securityCacheParams = getSecurityCacheData()
    const loginType = URL.getParam('loginType') || securityCacheParams.loginType

    this.saLogger.onPageView({
      ...loggerConfig.page_view,
      refNo: this.props.refNo,
      gatewayMerchantId: data?.gatewayMerchantId,
      gatewayMerchantName: data?.gatewayMerchantName,
      Aku_channelName: LOGIN_METHOD[loginType],
      appName: data?.appName,
    })
  }

  componentDidMount = async () => {
    const {
      getOrderInfo,
      history,
      saveEnterTime,
      saveOrderParam,
      countryCode,
      getPublicConfig,
      handleGeneratePaymentID,
    } = this.props
    const { state } = history.location
    BizTracker.enter({ sn: 390004 })
    // getBillDate()
    saveEnterTime()
    // 登录页面从url取的分期数通过路由参数传递
    let curPeriod = state ? state.curPeriod : null
    // 由于放量老系统跳转新系统路由参数丢失，需要重新从url取分期数
    if (!curPeriod) {
      curPeriod = helpers.URL.getParam('periods')
    }
    getPublicConfig()
    await getOrderInfo(curPeriod ? curPeriod : null)
    handleGeneratePaymentID() // 生成支付ID
    saveOrderParam(curPeriod ? curPeriod : null, countryCode)
    this.getAndReportConfig()
  }

  componentWillUnmount() {
    BizTracker.leave()
  }
}

const mapStateToProps = (state) => ({
  plans: state.getIn(['orderDetail', 'plans']),
  curPaymentStrategy: state.getIn(['orderDetail', 'curPaymentStrategy']),
  curPeriod: state.getIn(['orderDetail', 'curPeriod']),
  curMonthlyPay: state.getIn(['orderDetail', 'curMonthlyPay']),
  downpaymentRate: state.getIn(['orderDetail', 'downpaymentRate']),
  balance: state.getIn(['orderDetail', 'balance']),
  creditStatus: state.getIn(['orderDetail', 'creditStatus']),
  bankStatus: state.getIn(['orderDetail', 'bankStatus']),
  downPayment: state.getIn(['orderDetail', 'downPayment']),
  curPayment: state.getIn(['orderDetail', 'curPayment']),
  basicDialog: state.getIn(['orderDetail', 'basicDialog']),
  basicPopup: state.getIn(['orderDetail', 'basicPopup']),
  showRiskCaptchaDialog: state.getIn(['orderDetail', 'showRiskCaptchaDialog']),
  operationId: state.getIn(['orderDetail', 'operationId']),
  pageId: state.getIn(['orderDetail', 'pageId']),
  insufficientLimit: state.getIn(['orderDetail', 'insufficientLimit']),
  periodOfInterestFree: state.getIn(['orderDetail', 'periodOfInterestFree']),
  originPrice: state.getIn(['orderDetail', 'originPrice']), //商品真实价格。页面显示价格是在真实价格上向上取整
  showDownPaymentRate: state.getIn(['orderDetail', 'showDownPaymentRate']),
  orderButtonDisabled: state.getIn(['orderDetail', 'orderButtonDisabled']),
  showFullpayment: state.getIn(['orderDetail', 'showFullpayment']),
  orderDetailBanner: state.getIn(['orderDetail', 'orderDetailBanner']),
  coupons: state.getIn(['orderDetail', 'coupons']),
  securityVerificationVisible: state.getIn([
    'orderDetail',
    'securityVerificationVisible',
  ]),
  needSecurityVerification: state.getIn(
    'orderDetail',
    'needSecurityVerification',
  ),
  securityVerificationToggleLoginDialog: state.getIn(
    'orderDetail',
    'securityVerificationToggleLoginDialog',
  ),
  showSmallPayController: state.getIn([
    'orderDetail',
    'showSmallPayController',
  ]),
  isSmallPay: state.getIn(['orderDetail', 'isSmallPay']),
  firstNonPasswordPay: state.getIn(['orderDetail', 'firstNonPasswordPay']),
  quickPaymentReminderHasOpen: state.getIn([
    'orderDetail',
    'quickPaymentReminderHasOpen',
  ]),
  showPromptModal: state.getIn(['orderDetail', 'showPromptModal']),
  appId: state.getIn(['main', 'appId']),
  refNo: state.getIn(['main', 'refNo']),
  sign: state.getIn(['main', 'sign']),
  userSetRepaymentDate: state.getIn(['orderDetail', 'userSetRepaymentDate']),
  orderId: state.getIn(['orderDetail', 'orderId']),
  device_id: helpers.storage.get('deviceId') || 'unknown',
  showPayVerificationModal: state.getIn([
    'orderDetail',
    'showPayVerificationModal',
  ]),
  clickPayTime: state.getIn(['orderDetail', 'clickPayTime']),
  payPwdBusinessId: state.getIn(['orderDetail', 'paymentPasswordOperationId']),
  notice: state.getIn(['orderDetail', 'commonInfo', 'notice']),
})

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  saveOrderParam(curPeriod, countryCode) {
    dispatch(actionCreator.goSaveOrderParam(curPeriod, countryCode))
  },
  getBillDate() {
    dispatch(actionCreator.goGetBillDate())
  },
  getOrderInfo(curPeriod) {
    // 如果进入订单页设备sdk还没初始化，再获取一次设备信息
    if (!ALDeviceSDKInstance) {
      dispatch(globalActionCreator.globalAsyncGetDeviceData())
    }
    return dispatch(actionCreator.goGetOrderInfo(curPeriod))
  },
  choosePeriod(ALpayOrderID) {
    return (period, monthlyPay, downpayment, lastMonthlyPay) => {
      const saLogger = Logger.getInstance()
      saLogger.onPageClick({
        ...loggerConfig.choose_period,
        ALpayOrderID,
      })
      BizTracker.click({ cn: 5 })
      dispatch(
        actionCreator.doChoosePeriodLogic(
          period,
          monthlyPay,
          downpayment,
          lastMonthlyPay,
        ),
      )
    }
  },
  showFullPayDialog(content) {
    const translate = Translate.getInstance().translate

    dispatch(
      globalActionCreator.toggleAlertDialog(true, {
        title: content ? translate('分期失败') : translate('提示'), //TOFIX: 临时这样写的
        content: content
          ? content
          : translate(
              '基于COVID-19疫情期间您的最新信用评估，很抱歉您不能分期支付此订单，请使用全款支付。当此订单能通过分期支付时，我们会及时通知您。请注意安全和身体健康！',
            ),
        footer: [
          {
            text: translate('我知道了'),
            onPress: () => {
              dispatch(actionCreator.goChooseFullpayOnly())

              const saLogger = Logger.getInstance()
              saLogger.setOpenpayRiskStatus('全款')
            },
          },
        ],
      }),
    )
    dispatch(
      actionCreator.goToggleBasicDialog({
        show: false,
        dialogType: '',
        content: '',
      }),
    )
  },
  cancelOldPayment(content) {
    const translate = Translate.getInstance().translate

    dispatch(
      globalActionCreator.toggleAlertDialog(true, {
        title: translate('提示'),
        content,
        footer: [
          {
            text: translate('确认'),
            onPress: () => dispatch(actionCreator.goCancelOrder()),
          },
        ],
      }),
    )
    dispatch(
      actionCreator.goToggleBasicDialog({
        show: false,
        dialogType: '',
        content: '',
      }),
    )
  },
  //查看分期详情
  showPlanDetails() {
    dispatch(actionCreator.goToggleBasicPopup(true, 'planDetails'))
  },
  //查看首付比例列表
  showPyamentRateList() {
    // const saLogger = Logger.getInstance()
    // saLogger.onPageClick(elementId.CLICK_SHOW_PAY_RATE_POPUP)

    dispatch(actionCreator.goToggleBasicPopup(true, 'paymentRateList'))
  },
  // 查看优惠券
  showCouponListOrPromotionCode() {
    dispatch(actionCreator.goToggleBasicPopup(true, 'couponList'))
  },
  closePopup() {
    dispatch(actionCreator.goToggleBasicPopup(false))
  },
  //显示/隐藏提示框
  toggleBasicDialog({ show, content }) {
    dispatch(actionCreator.goToggleBasicDialog({ show, content }))
  },
  //下单前置操作
  opreateBeforePlaceOrder(curPeriod) {
    const translate = Translate.getInstance().translate
    const state = store.getState()
    const isSingleFullPayment = state.getIn([
      'orderDetail',
      'plans',
      'isSingleFullPayment',
    ])
    // const skipPassword = state.getIn(['orderDetail', 'skipPassword'])
    const isSmallPay = state.getIn(['orderDetail', 'isSmallPay'])
    const saLogger = Logger.getInstance()
    const needSecurityVerification = state.getIn([
      'orderDetail',
      'needSecurityVerification',
    ]) // 接口返回的是否需要安全组件
    const needRiskChcek = needSecurityVerification && curPeriod !== '0' //全款支付不需要经过风控验证
    const autoLoginTimestampInStorage = storage.getSession('autoLoginTimestamp')
    const showSmallPayController = state.getIn([
      'orderDetail',
      'showSmallPayController',
    ])

    //#region 神策埋点
    const payments = state.getIn(['orderDetail', 'plans', 'payments'])
    const downpaymentRate = state.getIn(['orderDetail', 'downpaymentRate'])
    // 当前选中的分期信息
    const curPeriodInfo = payments
      ?.toJS()
      [downpaymentRate]?.find(
        (item) => String(item.periods) === String(curPeriod),
      )

    helpers.storage.setSession('full_product_id', curPeriodInfo?.fullProductId)
    saLogger.click({
      ...loggerConfig.confirm_payment,
      Aku_product_id: curPeriodInfo?.fullProductId,
      skucreditTerm: curPeriod,
    })
    //#endRegion

    // 上报设备指纹信息
    dispatch(
      globalActionCreator.globalSendDeviceData({
        scenario_number: SCENARIO_NUMBER_MAP.ADD_ORDER,
      }),
    )

    if (curPeriod === '0' && isSingleFullPayment) {
      dispatch(
        globalActionCreator.toggleAlertDialog(true, {
          title: translate('提示'),
          content: translate('当前暂时无法提交订单，请稍后再试'),
          footer: [
            {
              text: translate('确认'),
            },
          ],
        }),
      )
      return
    }

    dispatch(
      actionCreator.goOpreateBeforePlaceOrder({
        needRiskChcek,
        isAutoLogin: !!autoLoginTimestampInStorage, // 是否是缓存自动登录，看sessionStorage 中是否有这个参数
        skipPassword: showSmallPayController && isSmallPay, // 是否跳过密码输入 由小额免密用户选择决定
      }),
    )

    // if (showSmallPayController && isSmallPay) {
    //   dispatch(actionCreator.goOpreateBeforePlaceOrder({ needRiskChcek }))
    // } else {
    //   //是否是快捷登录进来的用户
    //   if (autoLoginTimestampInStorage) {
    //     /*
    //      * 快捷支付需求,isNewPasswordProcessing为true表示该用户触发快捷支付
    //      * 通过自动登录下单的用户
    //      * 先调用风控验证接口
    //      * 1.不管风控是否需要校验，只要当天交易金额不超过150K，不需要输入密码直接下单
    //      * 2.如果风控不需要校验，且当天交易超过150K，则展示新输入密码/短信验证弹窗
    //      * 3.如果风控需要校验，则展示默认密码输入弹窗
    //      */

    //     dispatch(
    //       actionCreator.goOpreateBeforePlaceOrder({
    //         needRiskChcek,
    //         isAutoLogin: true,
    //         skipPassword,
    //       })
    //     )
    //   } else {
    //     dispatch(actionCreator.goOpreateBeforePlaceOrder({ needRiskChcek }))
    //   }
    // }
  },
  //取消订单
  cancelOrder() {
    const state = store.getState()
    const info = {
      appId: state.getIn(['main', 'appId']),
      refNo: state.getIn(['main', 'refNo']),
      sign: state.getIn(['main', 'sign']),
    }
    dispatch(actionCreator.goCancelOrder({ ...info }))
  },
  saveEnterTime() {
    dispatch(actionCreator.goSaveEnterTime(new Date().getTime()))
  },
  openAgreement(countryCode, languageCode) {
    BizTracker.click({ cn: 7 })
    const saLogger = Logger.getInstance()
    saLogger.onPageClick(loggerConfig.click_terms)
    // 20201208 协议页迁移到自己项目下
    dispatch(actionCreator.goToggleBasicPopup(true, 'ServiceAgreementIframe'))
    // const bizEnv = getBizEnv()
    // const domain = [
    //   'https://',
    //   bizEnv === 'prod' ? '' : `${bizEnv}-`,
    //   'mall.akulaku.com'
    // ].join('')
    // languageCode === 'EN'
    //   ? (window.location.href = `${domain}/v2/terms.html?lang=ph`)
    //   : (window.location.href = `${domain}/v2/terms.html?lang=${countryCode.toLowerCase()}`)
  },
  postRisckCheckCaptcha(captcha, operationId) {
    const isAutoLogin = storage.getSession('autoLoginTimestamp') ? true : false

    dispatch(actionCreator.postRiskCaptcha(captcha, operationId, isAutoLogin))
  },
  removeCouponCode() {
    // const translate = Translate.getInstance().translate
    // const saLogger = Logger.getInstance()

    // saLogger.onPageClick(elementId.CLICK_REMOVE_COUPON)

    dispatch(actionCreator.beforeRemoveCouponCode())
  },
  loginBeforePlaceOrder(loginPwd, curPeriod) {
    const state = store.getState()
    const needSecurityVerification = state.getIn([
      'orderDetail',
      'needSecurityVerification',
    ]) // 接口返回的是否需要安全组件
    const needRiskChcek = needSecurityVerification && curPeriod !== '0' //全款支付不需要经过风控验证

    dispatch(actionCreator.goLoginBeforePlaceOrder(loginPwd, needRiskChcek))
  },
  userSignOut() {
    const translate = Translate.getInstance().translate
    const saLogger = Logger.getInstance()
    BizTracker.click({ cn: 8 })
    saLogger.onPageClick(loggerConfig.return)
    dispatch(
      globalActionCreator.toggleAlertDialog(true, {
        title: translate('登出'),
        content: translate('确认退出登录？'),
        footer: [
          {
            text: translate('退出登录_dialog_确定按钮'),
            onPress: () => {
              saLogger.onPageClick(loggerConfig.logout_yes)
              dispatch(actionCreator.goLogOut())
            },
          },
          {
            text: translate('退出登录_dialog_取消按钮'),
            onPress: () => {
              saLogger.onPageClick(loggerConfig.logout_no)
            },
          },
        ],
      }),
    )
  },

  // 获取公共配置 暂时是只有是否需要安全组件
  getPublicConfig() {
    dispatch(actionCreator.goGetPublicConfig('mobile'))
  },
  // 安全组件校验成功
  securityVerificationOnSuccess({ operationId }) {
    const saLogger = Logger.getInstance()
    saLogger.click({
      ...loggerConfig.confirm_operation,
      Aku_operation_id: SECURITY_VERIFICATION_BIZ_TYPE.ADD_ORDER,
      return_code: 1, // 安全组件状态：1-验证通过、2-验证失败
    })
    const isAutoLogin = storage.getSession('autoLoginTimestamp') ? true : false
    const state = store.getState()
    // 20201202 是否跳过密码 只有小额免密有关系
    const showSmallPayController = state.getIn([
      'orderDetail',
      'showSmallPayController',
    ])
    const isSmallPay = state.getIn(['orderDetail', 'isSmallPay'])
    const skipPassword = showSmallPayController && isSmallPay
    dispatch(
      actionCreator.afterSecurityVerification({
        operationId,
        isAutoLogin,
        skipPassword,
      }),
    )
  },
  // 安全组件关闭
  securityVerificationOnClose() {
    dispatch(actionCreator.toggleSecurityVerification(false))
  },
  // 安全组件拒绝
  securityVerificationOnRejected(retry) {
    console.log(retry)
    const saLogger = Logger.getInstance()
    saLogger.click({
      ...loggerConfig.confirm_payment,
      code: 'unknown',
      Aku_msg: 'security verification reject',
    })
    saLogger.click({
      ...loggerConfig.confirm_operation,
      Aku_operation_id: SECURITY_VERIFICATION_BIZ_TYPE.ADD_ORDER,
      return_code: 2, // 安全组件状态：1-验证通过、2-验证失败
    })
  },
  // 安全组件登录态没有退出
  securityVerificationLogout() {
    dispatch(loginActionCreator.goSetNeedLogoutToast(true))
    dispatch(globalActionCreator.smartJumpToLoginPage())
  },
  // 支付密码组件校验成功
  paymentPasswordVerificationSuccess({ businessId }) {
    dispatch(
      actionCreator.setPasswordVerificationData({
        paymentPasswordCheckPass: true,
      }),
    )
    dispatch(actionCreator.togglePaymentPasswordVerification(false))
    dispatch(actionCreator.continueRiskCheck({ operationId: businessId }))
  },
  // 支付密码组件校验失败
  paymentPasswordVerificationFail(err) {
    console.log(err)
    const saLogger = Logger.getInstance()
    saLogger.click({
      ...loggerConfig.confirm_payment,
      code: err?.error?.errCode,
      Aku_msg: err?.error?.errMsg,
    })
    // dispatch(globalActionCreator.toggleToast(true, err.errorMsg || err.message))
  },
  // 支付密码组件校验关闭
  paymentPasswordVerificationClose(err) {
    dispatch(actionCreator.__disableOrderButton(false))
    dispatch(actionCreator.togglePaymentPasswordVerification(false))
  },
  goSetIsSmallPay(isSmallPay) {
    dispatch(actionCreator.goSetIsSmallPay(isSmallPay))
  },
  openSmallPayAgreement(countryCode, languageCode) {
    BizTracker.click({ cn: 7 })
    // 产品（亨菲）说只做印尼文 没有其他国家
    dispatch(
      push({
        pathname: './terms/quickPay',
        search: window.location.search,
      }),
    )
  },
  handelShowPromptModal(state) {
    dispatch(actionCreator.goSetShowPromptModal(state))
    // 关闭弹窗时刷新支付id，防止支付id重复导致流程中断
    dispatch(actionCreator.generatePaymentID())
  },
  handleGeneratePaymentID() {
    dispatch(actionCreator.generatePaymentID())
  },
  paymentPasswordReportDeviceData() {
    // pin码 支付验证上报自研设备信息
    dispatch(
      globalActionCreator.globalSendDeviceData({
        scenario_number: SCENARIO_NUMBER_MAP.PAYMENT_PASSWORD,
      }),
    )
  },
  async paymentPasswordGetSmDeviceData() {
    // pin码 支付验证上报数美设备信息
    const smDeviceData = await getSmDeviceData()
    return smDeviceData
  },
})

export default withPerfCollect()(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(OrderDetails)),
)
