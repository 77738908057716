import React, { PureComponent } from 'react'
import { Modal } from 'antd-mobile'
import { connect } from 'react-redux'
import { debounce } from 'lodash'

import { helpers, withBasic } from '@common'
import { PasswordInput, CodeInput } from '../../../login/components/InputFields'
import { ModalTitle } from '@pc/components/ModalTitle'
import Logger, * as elementId from '@pc/config/logger'
import { actionCreator } from '../../store'
import loggerConfig from '../../logger.config'

import styles from './style.m.scss'

const className = helpers.classNames.react(styles)

@withBasic
class PasswordAndCodeForm extends PureComponent {
  constructor() {
    super()
    this.state = {
      formType: 'loginPassword', //表单类型。默认密码，可切换成短信sms
      formData: {
        loginSmsCode: '',
        loginPassword: '',
      },
    }
    this.controls = []
    this.beforeSubmit = this.beforeSubmit.bind(this)
    this.saLogger = Logger.getInstance()
  }

  componentDidUpdate(prevProps) {
    if (
      !prevProps.showPasswordAndCodeForm &&
      this.props.showPasswordAndCodeForm
    ) {
      this.saLogger.onPageClick(
        this.state.formType === 'loginPassword'
          ? loggerConfig.payment_popup
          : loggerConfig.verification_popup,
      )
    }
  }

  //保存控件实例到数组中
  register = (instance) => {
    this.controls.push(instance)
  }

  //收集控件value值保存到表单state中
  collect = (e) => {
    this.setState({
      formData: {
        [e.target.name]: e.target.value,
      },
    })
  }

  beforeSubmit = () => {
    const { setLoginPwdErrorText } = this.props
    //提交表单前，每一个控件应该通过数据校验
    let result, errMsg

    result = this.controls.every((elem) => {
      if (elem.validate(elem.state.value) === false) {
        return true
      } else {
        errMsg = elem.validate(elem.state.value)
        setLoginPwdErrorText(errMsg)
        return false
      }
    })

    return result
  }

  submit = () => {
    const {
      formData: { loginPassword, loginSmsCode },
      formType,
    } = this.state
    const { submitPassword, submitSmsCode } = this.props

    if (this.beforeSubmit()) {
      formType === 'loginPassword'
        ? submitPassword(loginPassword)
        : submitSmsCode(loginSmsCode)
    }
  }

  //如果控件被销毁，则要重置数组
  removeController = (name) => {
    if (!name) return

    this.controls = this.controls.filter((elem) => {
      if (elem.name !== name) return elem
    })
  }

  toggleFormType = () => {
    const { setLoginPwdErrorText } = this.props
    const formType =
      this.state.formType === 'loginPassword' ? 'loginSmsCode' : 'loginPassword'

    setLoginPwdErrorText('')

    this.removeController(this.state.formType)

    this.setState({
      formType,
    })

    formType === 'loginPassword'
      ? this.saLogger.onPageClick(loggerConfig.payment_popup)
      : this.saLogger.onPageClick(loggerConfig.verification_popup)
  }

  onForgot = () => {
    this.setState({
      forgotPwd: true,
    })
  }

  render() {
    const {
      showPasswordAndCodeForm,
      translate,
      setLoginPwdErrorText,
      togglePwdAndCodeForm,
      sendCode,
      sent,
      countDown,
      orderButtonDisabled,
    } = this.props
    const { loginPwdErrorText } = this.props
    const { formType } = this.state

    return (
      <form {...className('login-password-sms-form')}>
        <Modal
          visible={showPasswordAndCodeForm}
          transparent
          maskClosable={false}
          title={
            <ModalTitle
              title={
                formType === 'loginPassword'
                  ? translate('请输入您的登录密码')
                  : translate('验证码')
              }
              onClick={() => {
                setLoginPwdErrorText('')
                togglePwdAndCodeForm(false)
                this.removeController(formType)

                formType === 'loginPassword'
                  ? this.saLogger.onPageClick(loggerConfig.password_close)
                  : this.saLogger.onPageClick(
                      loggerConfig.verification_code_close,
                    )
              }}
            />
          }
          footer={[
            {
              text: translate('提交'),
              onPress: () => {
                formType === 'loginPassword'
                  ? this.saLogger.onPageClick(loggerConfig.password_confirm)
                  : this.saLogger.onPageClick(
                      loggerConfig.verification_code_confirm,
                    )
                debounce(this.submit, 300)()
              },
            },
            {
              text:
                formType === 'loginPassword'
                  ? translate('使用短信')
                  : translate('使用aku密码'),
              onPress: () => {
                this.toggleFormType()
              },
            },
          ]}
        >
          {formType === 'loginPassword' ? (
            <div {...className('login-password-content')}>
              <PasswordInput
                type="password"
                name="loginPassword"
                placeholder={translate('请输入密码（8-16位字符）')}
                onRegister={this.register}
                onControl={this.collect}
                errorText={loginPwdErrorText}
                validation={{ type: 'password' }}
                onFocus={() => {
                  setLoginPwdErrorText('')
                  this.saLogger.onPageClick(loggerConfig.click_password_box)
                }}
                onBlur={() => {
                  this.saLogger.onPageClick(loggerConfig.input_password)
                }}
                required
              />
            </div>
          ) : (
            <div {...className('login-sms-content')}>
              <CodeInput
                type="text"
                name="loginSmsCode"
                placeholder={translate('请输入验证码')}
                onRegister={this.register}
                onControl={this.collect}
                errText={loginPwdErrorText}
                validation={{ type: 'code' }}
                btnText={sent ? `${countDown}s` : translate('发送')}
                btnDisable={sent}
                onFocus={() => {
                  setLoginPwdErrorText('')
                  // this.saLogger.onPageInput(elementId.RISK_SMS_INPUT, 'begin')
                }}
                onBlur={() => {
                  // this.saLogger.onPageInput(elementId.RISK_SMS_INPUT, 'end')
                }}
                onSend={() => {
                  this.saLogger.onPageClick(loggerConfig.verification_code_send)
                  sendCode()
                }}
                required
              />
            </div>
          )}
        </Modal>
      </form>
    )
  }
}

const mapStateToProps = (state) => ({
  showPasswordAndCodeForm: state.getIn([
    'orderDetail',
    'showPasswordAndCodeForm',
  ]),
  loginPwdErrorText: state.getIn(['orderDetail', 'loginPwdErrorText']),
  sent: state.getIn(['orderDetail', 'sent']),
  countDown: state.getIn(['orderDetail', 'countDown']),
  orderButtonDisabled: state.getIn(['orderDetail', 'orderButtonDisabled']),
})

const mapDispatchToProps = (dispatch) => ({
  setLoginPwdErrorText(errText) {
    dispatch(actionCreator.goSetLoginPwdErrorText(errText))
  },
  togglePwdAndCodeForm(show) {
    dispatch(actionCreator.togglePwdAndCodeForm(show))
  },
  submitPassword(pwd) {
    dispatch(actionCreator.goLoginBeforePlaceOrder(pwd))
  },
  submitSmsCode(smsCode) {
    dispatch(actionCreator.doAddOrderLogic(smsCode))
  },
  sendCode() {
    dispatch(actionCreator.getOrderCaptcha())
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(PasswordAndCodeForm)
